import React from 'react';

import Meta from '../components/meta';

import { Link } from 'gatsby';

import Contents from '../components/contents';

const Specific = () =>{



    return(
        <>

            <Meta
                title="特定個人情報等の適正な取扱いに関する基本方針"
                description="アクロスソリューションズの特定個人情報等の適正な取扱いに関する基本方針について"
            />

            <Contents>

                <article className="p-recruit-jobs is-policy">

                    <section className="p-recruit-jobs-section">

                        <div className="p-recruit-section-box is-detail">
                            <div className="p-recruit-job">
                                <p className="p-recruit-job--text">
                                特定個人情報等の適正な取扱いに関する基本方針
                                </p>
                            </div>

                            <div className="p-recruit-data">

                                <div className="p-recruit-data-box">
                                    <div className="p-recruit-data-box-cnt">
                                        <p className="p-recruit-data-box-cnt--text">
                                        当社は、「行政手続における特定の個人を識別するための番号の利用等に関する法律」（以下、「マイナンバー法」といいます）に基づく特定個人情報及び個人番号（以下、「特定個人情報等」といいます）の適正な取扱いの確保について次の通り基本方針を定めます。
                                        </p>
                                    </div>
                                </div>

                                <div className="p-recruit-data-box">
                                    <p className="p-recruit-data-box--title">
                                    1.事業者の名称
                                    </p>

                                    <div className="p-recruit-data-box-cnt">
                                        <p className="p-recruit-data-box-cnt--text">
                                        株式会社アクロスソリューションズ
                                        </p>
                                    </div>
                                </div>

                                <div className="p-recruit-data-box">
                                    <p className="p-recruit-data-box--title">
                                    2.関係法令・ガイドライン等の遵守
                                    </p>

                                    <div className="p-recruit-data-box-cnt">
                                        <p className="p-recruit-data-box-cnt--text">
                                        当社は、マイナンバー法、「個人情報の保護に関する法律」その他の法令及び「特定個人情報の適正な取扱いに関するガイドライン（事業者編）」その他のガイドラインを遵守して、特定個人情報等の適正な取扱いを行います。
                                        </p>
                                    </div>
                                </div>

                                <div className="p-recruit-data-box">
                                    <p className="p-recruit-data-box--title">
                                    3.利用目的
                                    </p>

                                    <div className="p-recruit-data-box-cnt">
                                        <p className="p-recruit-data-box-cnt--text">
                                        当社は、特定個人情報等について、以下の利用目的で利用します。
                                        </p>

                                        <div className="p-recruit-data-box-list">
                                            <ul className="p-recruit-data-box-lists is-decimal">
                                                <li>
                                                源泉徴収関連事務等
                                                </li>
                                                <li>
                                                扶養控除等（異動）申告書、保険料控除申告書兼給与所得者の配偶者特別控除申告書作成事務等
                                                </li>
                                                <li>
                                                給与支払報告書作成事務等
                                                </li>
                                                <li>
                                                給与支払報告特別徴収に係る給与所得者異動届出書作成事務等
                                                </li>
                                                <li>
                                                特別徴収への切替申請書作成事務等
                                                </li>
                                                <li>
                                                退職手当金等受給者別支払調書作成事務等
                                                </li>
                                                <li>
                                                退職所得に関する申告書作成事務等
                                                </li>
                                                <li>
                                                財産形成住宅貯蓄・財産形成年金貯蓄に関する申告書、届出書及び申込書作成事務等
                                                </li>
                                                <li>
                                                健康保険、厚生年金、企業年金届出事務等
                                                </li>
                                                <li>
                                                国民年金第三号届出事務等
                                                </li>
                                                <li>
                                                健康保険、厚生年金、企業年金申請・請求事務等
                                                </li>
                                                <li>
                                                雇用保険、労災保険届出事務等
                                                </li>
                                                <li>
                                                雇用保険、労災保険申請・請求事務等
                                                </li>
                                                <li>
                                                雇用保険、労災保険証明書作成事務等
                                                </li>
                                                <li>
                                                持株会に係る金融商品取引に関する法定書類の作成・提供事務
                                                </li>
                                                <li>
                                                報酬・料金等の支払調書作成事務
                                                </li>
                                                <li>
                                                配当、剰余金の分配及び基金利息の支払調書作成事務
                                                </li>
                                                <li>
                                                不動産の使用料等の支払調書作成事務
                                                </li>
                                                <li>
                                                不動産等の譲受けの対価の支払調書作成事務
                                                </li>
                                                <li>
                                                業務受託契約に基づく上記事務の実施
                                                </li>
                                                <li>
                                                業務受託契約に基づく特定個人情報等の管理等業務、および関連事務
                                                </li>
                                                <li>
                                                上記に関連する事務
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="p-recruit-data-box">
                                    <p className="p-recruit-data-box--title">
                                    4.安全管理措置に関する事項
                                    </p>

                                    <div className="p-recruit-data-box-cnt">
                                        <p className="p-recruit-data-box-cnt--text">
                                        当社は、特定個人情報等について、漏えい、滅失等の防止その他管理のために必要かつ適切な安全管理措置を講じます。また、特定個人情報等を取り扱う従業者や委託先（再委託先等を含みます）に対して、必要かつ適切な監督を行います。特定個人情報等の安全管理措置に関しては、別途社内規程を制定します。
                                        </p>
                                    </div>
                                </div>

                                <div className="p-recruit-data-box">
                                    <p className="p-recruit-data-box--title">
                                    5.特定個人情報等に関する問合せ窓口
                                    </p>

                                    <div className="p-recruit-data-box-cnt">
                                        <p className="p-recruit-data-box-cnt--text">
                                        当社における特定個人情報等の取扱いに関するお問い合わせに関しては下記の窓口にご連絡ください。
                                        </p>

                                        <p className="p-recruit-data-box-cnt--text">
                                        【会社名】株式会社アクロスソリューションズ<br />
                                        【窓口部署】本社 管理部<br />
                                        【電話番号】076-255-2012（9:00～18:00） 
                                        </p>
                                    </div>
                                </div>

                                <div className="p-recruit-data-box">
                                    <div className="p-recruit-data-box-cnt">
                                        <p className="p-recruit-data-box-cnt--text">
                                        本方針は、従業員及びその他従業者等に周知、徹底するとともに、当社内外に対しても公表いたします。また、従業員及びその他従業者等の教育、啓発に努め、特定個人情報保護意識の維持・向上を図ります。
                                        </p>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div className="p-recruit-entry is-single">
                            <Link to="/privacyform" title="メールフォームでのお問い合わせ" className="p-recruit-entry--btn">
                            メールフォームでのお問い合わせ
                            </Link>
                        </div>

                        <div className="p-recruit-data-box-sign">
                            <p className="p-recruit-data-box-cnt--text">
                            2022年4月1日<br />
                            株式会社アクロスソリューションズ<br />
                            代表取締役社長　能登 満
                            </p>
                        </div>
                    </section>

                </article>

            </Contents>

        </>
    )
}

export default Specific;


